import HTTP from './default';

export default {
    getFaq() {
        return HTTP({
            url: '/faq/',
            method: 'GET',
            headers: {
                Authorization: `Token ${localStorage.getItem('userToken')}`,
            },
        });
    },
};
