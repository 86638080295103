<template>
  <div class="faq-item toggle-block" :class="{'active' : isOpened}">
    <div class="faq-title toggle-button"
         @click="isOpened = !isOpened">
      {{ question }}
    </div>
    <VueSlideToggle :open="isOpened">
      <div class="toggle-content" style="display: block">
      <div class="faq-content">
        <div class="text" v-html="answer"></div>
      </div>
    </div>
    </VueSlideToggle>
  </div>
</template>

<script>
import {VueSlideToggle} from 'vue-slide-toggle';

export default {
  name: 'FaqItem',

  props: {
    question: String,
    answer: String,
  },

  data() {
    return {
      isOpened: false,
    };
  },

  components: {
    VueSlideToggle,
  },
};
</script>

<style scoped>

</style>