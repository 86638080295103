<template>
  <section class="section wow fadeIn" data-wow-offset="0">
    <div class="content">
      <ul class="breadcrumbs">
        <li>
          <router-link to="/">Главная</router-link>
        </li>
        <li><span>FAQ</span></li>
      </ul>
      <h1 class="page-title">FAQ</h1>
      <div v-if="faq && faq.length > 0" class="faq-group">
        <faq-item v-for="question in faq" :key="question.id"
                  :question="question.title"
                  :answer="question.description" />
      </div>
    </div>
  </section>
</template>

<script>
import FaqItem from '@/components/FaqItem';
import Faq from '@/api/faq';

export default {
  name: 'Faq',

  data() {
    return {
      faq: null,
    };
  },

  created() {
    this.getFaq();
  },

  methods: {
    async getFaq() {
      const response = await Faq.getFaq();
      this.faq = response.data;
    },
  },

  directives: {},

  components: {
    FaqItem,
  },
};
</script>

